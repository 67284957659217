import React, { Component }  from 'react';


const Logo = ({ color = '#111' }) => (
  <svg style={{fill: color}} width="56" height="13" viewBox="0 0 56 13" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.14099 4.428H5.65299L5.91899 4.694V7.704L5.65299 7.97H4.21099L3.51099 7.452V5.156L4.14099 4.428ZM3.58099 2.398L2.81099 3.28L2.55899 2.188L0.836992 2.58L1.34099 4.764V12.352H3.51099V9.482L4.21099 10H6.57699L8.08899 8.502V3.896L6.57699 2.398H3.58099Z"/>
    <path d="M10.4642 4.708V8.502L11.9622 10H13.9362L16.0362 9.342L15.4342 7.424C14.9442 7.578 14.2022 7.816 13.6982 7.97H12.9702L12.6342 7.634V4.708H14.5242V2.678H12.6342V0.48H10.4642V2.678H9.20418V4.708H10.4642Z"/>
    <path d="M18.5923 4.764V4.568L18.8723 4.288H20.6783L22.4983 4.89L23.1003 3.07L21.0703 2.398H17.9203L16.4223 3.896V5.534L17.0523 6.57L20.6923 7.452L20.8043 7.62V7.83L20.5243 8.11H18.7323L16.9123 7.508L16.3103 9.328L18.3403 10H21.4763L22.9743 8.502V6.836L22.3443 5.8L18.7043 4.918L18.5923 4.764Z"/>
    <path d="M27.1097 4.428H28.6217L28.8877 4.694V7.704L28.6217 7.97H27.1797L26.4797 7.452V5.156L27.1097 4.428ZM26.5497 2.398L25.7797 3.28L25.5277 2.188L23.8057 2.58L24.3097 4.764V12.352H26.4797V9.482L27.1797 10H29.5457L31.0577 8.502V3.896L29.5457 2.398H26.5497Z"/>
    <path d="M38.9349 7.634V3.896L37.4229 2.398H34.3009L32.2429 3.042L32.8169 4.848L34.5669 4.288H36.4989L36.7649 4.554V5.184H33.6849L32.1729 6.682V8.502L33.6849 10H36.6949L37.4649 9.118L37.7169 10.21L39.4389 9.818L38.9349 7.634ZM36.7649 7.522L36.1349 8.25H34.6089L34.3429 7.984V7.2L34.6089 6.934H36.7649V7.522Z"/>
    <path d="M45.1104 7.97H42.9964L42.7304 7.704V4.694L42.9964 4.428H44.8724L45.1384 4.694V5.45H47.3084V3.896L45.7964 2.398H42.0724L40.5604 3.896V8.502L42.0724 10H45.3764L47.4764 9.342L46.8604 7.41L45.1104 7.97Z"/>
    <path d="M52.8343 8.11H50.7483L50.4823 7.844V7.2H53.6883L55.0603 5.842V3.896L53.5483 2.398H49.8243L48.3123 3.896V8.502L49.8243 10H53.1003L55.1583 9.356L54.5843 7.55L52.8343 8.11ZM50.7483 4.148H52.6243L52.8903 4.414V5.254L52.6943 5.45H50.4823V4.414L50.7483 4.148Z"/>
  </svg>
)

Logo.propTypes = {
  // label: PropTypes.string.isRequired,
}

Logo.defaultProps = {
  // siteTitle: ``,
}

export default Logo
