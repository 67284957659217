import { Link } from "gatsby"
import React from "react"
import './styles.scss'



const SideNavLeft = ({ children }) => (
  <nav className="left">
    {/* <Flex className="side-nav-item" onClick={() => transitionLinkTo('to-index')}><Logo /></Flex>
    <Flex className="side-nav-item"><HelpOutlineIcon color="#666"/> Call or text +1 (206) 823-1434</Flex>
    <Flex className="side-nav-item" style={{ flex: 1 }}></Flex>
    <a rel="noopener" href="https://app.ptspace.com" className="side-nav-item"><LaunchIcon color="#666"/> Join ptspace</a> */}
  </nav>
)

export default SideNavLeft
