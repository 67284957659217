import React from 'react'
import Logo from '../components/icons/logo'

export default function Layout({ children }) {
  return (
    <div className="mt-24 footer">
      <div className="flex flex-wrap px-3 py-12">
        <div className="mb-6 footer-group">
          <div className="title">Browse</div>
          <div>All Workouts</div>
          <div>At Home Workouts</div>
          <div>Free Workouts</div>
        </div>
        <div className="footer-group">
          <div className="title">Company</div>
          <div>About Us</div>
          <div>Help and Support</div>
          <div>Terms of Service</div>
          <div>Privacy Policy</div>
          <div>Contact Us</div>
        </div>
      </div>
      <div className="p-3">
        <Logo color="#fff" />
        Copyright © 2021 All rights reserved
      </div>
    </div>
  )
}