import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import WorkoutCard from "../components/workout-card"
import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import 'swiper/swiper.scss';
import MenuIcon from '../components/icons/menu';
import Logo from '../components/icons/logo';
import ScreenWithSideNav from '../components/common/screens/screen-with-side-nav';
import Layout from '../components/layout';


const WorkoutBuilderPage = (props) => {
  return (
    <Layout>
      <h1 className="pt-8 mx-4 text-center">Free and simple<br/>Workout Builder</h1>
      <h3 className="text-center">Create and share workouts</h3>
      {/* <li>Pete's Leg Distruction workout</li>
      <li>Full Body Beast Mode</li>
      or you could assign them to your clients and automate */}
      <div className="text-center">
        <a rel="noopener" href="https://app.ptspace.com/install" className="btn">Install</a>
      </div>
    </Layout>
  )
}

export default WorkoutBuilderPage

// export const query = graphql`
// query {
//   allWorkout {
//     nodes {
//       id
//       title,
//       level,
//       trainer {
//         firstName
//       }
//       localImage {
//         childImageSharp {
//           gatsbyImageData(layout: FULL_WIDTH)
//         }
//       }
//     }
//   }
// }
// `