import { Link } from "gatsby"
import React from "react"
import './styles.scss'
import Logo from '../../icons/logo'

// const url = 'http://10.1.1.17:5001/ptspace-87497/us-central1/conversationsPublic?'

export default class SideNavRight extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  
  render() {
    return (
      <nav className="right">
        <div className="side-nav-item"><Logo color="#ddfe67"/></div>
        <a rel="noopener" href="https://app.ptspace.com" className="side-nav-item"><span className="material-icons">fitness_center</span>Workout Builder</a>
        <a rel="noopener" href="https://app.ptspace.com" className="side-nav-item"><span className="material-icons">attach_money</span>Sell your workout</a>
        <a className="side-nav-item"><span className="material-icons">help_outline</span>How it works</a>
      </nav>
    )
  }
}
