import React from "react"
import Footer from './footer'
import ScreenWithSideNav from '../components/common/screens/screen-with-side-nav'


export default function Layout({ children }) {
  return (
    <div className="prose">
      <ScreenWithSideNav>
        <main>
          {children}
        </main>
        <Footer />
      </ScreenWithSideNav>
    </div>
  )
}