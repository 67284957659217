import React from 'react'
// import Header from '../header'
import classNames from 'classnames'
import SideNavLeft from '../side-nav/side-nav-left'
import SideNavRight from '../side-nav/side-nav-right'
import './styles.scss'
import MenuIcon from '../../icons/menu'
import Logo from '../../icons/logo'
import ForumIcon from '../../icons/menu'


export default class ScreenWithSideNav extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      isLeftOpen: false,
      isRightOpen: false
    }
  }

  openLeft() {
    this.setState({ isLeftOpen: true, lastOpen: 'left' })
  }

  openRight() {
    this.setState({ isRightOpen: true, lastOpen: 'right' })
  }

  closeLeft() {
    this.setState({ isLeftOpen: false})
  }

  closeRight() {
    this.setState({ isRightOpen: false })
  }

  close() {
    if (this.state.isLeftOpen) {
      this.closeLeft()
    }
    if (this.state.isRightOpen) {
      this.closeRight()
    }
  }

  transitionLinkTo(id) {
    document.getElementById(id).click()
  }

  render() {
    const mainClass = classNames({
      'screen': true,
      'screen-with-side-nav': true,
      'reveal-left': this.state.isLeftOpen,
      'reveal-right': this.state.isRightOpen,
      'last-reveal-left': this.state.lastOpen === 'left',
      'last-reveal-right': this.state.lastOpen === 'right',
      'muted': this.props.muted
    });

    return (
      <div className={mainClass}>
        <SideNavLeft />
        <SideNavRight />
        <main>
          <div className="flex items-center justify-between px-8 py-6 mb-8">
            <div onClick={() => this.openLeft()}><Logo/></div>
            <div onClick={() => this.openRight()}><MenuIcon /></div>
          </div>
          {this.props.children}
        </main>
        <div className="overlay" onClick={() => this.close()}></div>
      </div>
    )
  }

}
